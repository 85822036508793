<template>
  <v-card class="main pa-4">
    <v-row class="query">
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          dense
          class="mr-2"
          label="域名/IPV6地址"
          v-model="query"
          v-on:keyup.enter="handleQuery"
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="2">
        <v-select
          dense
          class="mr-2"
          v-model="status"
          :items="statusList"
          item-text="text"
          item-value="value"
          label="状态"
          clearable
        ></v-select>
      </v-col>
      <v-col><v-btn color="primary" @click="handleQuery">查询</v-btn> </v-col>
    </v-row>
    <div>
      <v-data-table
        :headers="headers"
        :items="dataList"
        :loading="loading"
        hide-default-footer
        :items-per-page.sync="options.itemsPerPage"
        :page.sync="options.page"
      >
        <template v-slot:item.no="{ item, index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.status="{ item }">
          {{
            item.status != "0"
              ? item.status != "1"
                ? "已停用"
                : "已使用"
              : "未使用"
          }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{
            item.created_at.length > 19
              ? item.created_at.substring(0, 19).replace("T", " ")
              : item.created_at
          }}
        </template>
        <template v-slot:item.updated_at="{ item }">
          {{
            item.updated_at.length > 19
              ? item.updated_at.substring(0, 19).replace("T", " ")
              : item.updated_at
          }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item.id)" color="green">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item.id)" color="red">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <pagination :input="{ total: total }" @output="output" />
    </div>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h7 lighten-2">
          是否删除此地址资源?
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="del"> 确认</v-btn>
          <v-btn color="primary" text @click="dialog = false">取消 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Pagination from "../../components/elements/XPagination.vue";
export default {
  components: { Pagination },
  name: "CaseListPage",
  data() {
    return {
      headers: [
        {
          text: "序号",
          align: "start",
          sortable: false,
          value: "no",
        },
        { text: "域名", value: "domain" },
        { text: "IPV6地址", value: "ip_addr_v6" },
        { text: "状态", value: "status" },
        { text: "创建时间", value: "created_at" },
        { text: "更新时间", value: "updated_at" },
        { text: "操作", value: "actions", sortable: false },
      ],
      dataList: [],
      handleId: 0,
      dialog: false,
      loading: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: this.$store.getters.pageSize,
      },
      query: "",
      status: "",
      statusList: [
        // {
        //   value: "",
        //   text: "全部",
        // },
        {
          value: 0,
          text: "未使用",
        },
        {
          value: 1,
          text: "已使用",
        },
        {
          value: 2,
          text: "已停用",
        },
      ],
    };
  },
  methods: {
    output(data) {
      console.log(data);
      this.options = data;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.$axios
        .get(
          `/trunkserver/assets/address-pool/?page=${this.options.page}&&size=${this.options.itemsPerPage}`
        )
        .then((res) => {
          console.log(res);
          if (res.data.results && res.data.results.length > 0) {
            this.dataList = res.data.results;
            this.total = res.data.count;
          } else {
            this.dataList = [];
            this.total = 0;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    editItem(id) {
      sessionStorage.setItem("addressPoolId", id);
      this.$router.push({
        path: "/domain/address_pool_add",
      });
    },
    add() {
      sessionStorage.setItem("addressPoolId", 0);
      this.$router.push({
        path: "/domain/address_pool_add",
      });
    },
    deleteItem(id) {
      this.handleId = id;
      this.dialog = true;
    },
    del() {
      this.$axios
        .delete(`/trunkserver/assets/address-pool/${this.handleId}/`)
        .then((res) => {
          console.log(res);
          if (res.status > 199) {
            this.$message.success("此地址资源已删除！");
            this.dialog = false;
            this.getList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleQuery() {
      this.loading = true;
      this.$axios
        .get(
          `/trunkserver/assets/address-pool/?search=${this.query}&status=${
            this.status == null ? "" : this.status
          }`
        )
        .then((res) => {
          if (res.data.results && res.data.results.length > 0) {
            this.dataList = res.data.results;
            this.total = res.data.count;
          } else {
            this.dataList = [];
            this.total = 0;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
  created() {
    this.getList();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.query {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}
</style>
